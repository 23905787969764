const defaultConfig = {
  id: null,
  product: "ai-chat",
  settings: {
    serviceType: "Article Composer",
    language: "English (British English)",
    articleTone: "Informative",
    writingStyle: "",
  },
  options: {
    frequency_penalty: 0.2,
    max_length: null,
    max_tokens: 3000,
    messages: [],
    model: "gpt-4",
    presence_penalty: 0.2,
    response_format: { "type": "text" },
    temperature: 0.5,
    n: 1,
    user: null,
    tool_choice: 'auto'
  },
  user_id: null,
};

const deepMerge = (target, source) => {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }

  return { ...target, ...source };
};

const validateAndFormatTemperature = (temperature) => {
  let formattedTemp = parseFloat(temperature);
  if (isNaN(formattedTemp) || formattedTemp < 0 || formattedTemp > 1) {
    console.warn('Temperature value is invalid, setting to default 0.5');
    formattedTemp = 0.5; // Default value if invalid
  }
  return formattedTemp;
};

export const generateConfig = (configOverrides = {}, enableMergedSettings = true) => {
  // Check if there are saved settings in localStorage
  const savedSettings = JSON.parse(localStorage.getItem("settings")) || {};

  // Extract modelId from savedSettings or use the default modelId if not present
  const modelIdFromSavedSettings = savedSettings.modelId || defaultConfig.options.model;

  // Remove modelId from savedSettings so it doesnt get saved in the db in the wrong place
  if (savedSettings?.modelId) {
    delete savedSettings.modelId;
  }

  // Ensure temperature is in correct format for savedSettings at the top level
  if (savedSettings.temperature !== undefined) {
    savedSettings.temperature = validateAndFormatTemperature(savedSettings.temperature);
  }

  // Ensure temperature is in correct format for configOverrides, if present
  if (configOverrides.options && configOverrides.options.temperature !== undefined) {
    configOverrides.options.temperature = validateAndFormatTemperature(configOverrides.options.temperature);
  }

  // Merge savedSettings.settings and configOverrides.settings, with configOverrides.settings taking precedence
  const mergedSettings = deepMerge(savedSettings, configOverrides.settings);

  // Merge defaultConfig with configOverrides, with configOverrides taking precedence
  const mergedConfig = deepMerge(defaultConfig, configOverrides);

  // Update modelId in mergedConfig.model.id
  mergedConfig.options.model = modelIdFromSavedSettings;

  // Merge mergedConfig with the already merged settings
  const finalConfig = {
    ...mergedConfig,
    settings: enableMergedSettings ? { ...defaultConfig.settings, ...mergedSettings } : { ...mergedConfig.settings },
  };

  return finalConfig;
};
