import React, { useState, useEffect, useContext } from "react";
import { format, parseISO, formatDistanceToNow } from 'date-fns';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Grid, Button, Divider, TextField, IconButton, useMediaQuery } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import usersApi from "../server/usersApi";
import { userProfileContext } from "../lib/UserProvider";

const EditableTitleCell = ({ articleId, initialValue, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent event from propagating to the parent
    setIsEditing(true);
  };

  const handleChange = (event) => setValue(event.target.value);
  const handleBlur = () => {
    onUpdate(articleId, value);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <TextField
        size="small"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
        fullWidth
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Center align the contents
        width: '100%' // Ensure the div takes full width of the cell
      }}
    >
      <span>{value}</span>
      <IconButton
        size="small"
        onClick={handleEditClick}
        style={{
          marginLeft: '5px',
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </div>
  );
};


const IndexPage = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user_id: userProfileId } = useContext(userProfileContext) || {};

  useEffect(() => {
    if (userProfileId) {
      const fetchChatList = async () => {
        const data = await usersApi.fetchUserMessages(userProfileId);
        if (Array.isArray(data)) {
          const sortedData = data.sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date));
          setArticles(sortedData);
        }
      };
      fetchChatList();
    }
  }, [userProfileId]);

  const handleCreateChat = () => navigate("/ai-chat");

  const redirectToAiChat = (articleId) => {
    navigate(`/ai-chat/${articleId}`);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateArticleTitle = async (articleId, newTitle) => {
    const updates = { name: newTitle }; // Construct the updates object
    try {
      await usersApi.updateUserMessage(userProfileId, articleId, updates);
      setArticles(articles.map(article => article.id === articleId ? { ...article, name: newTitle } : article));
    } catch (error) {
      console.error("Failed to update article:", error);
    }
  };

  return (
    <div>
      <Grid container spacing={isMobile ? 1 : 2} justifyContent="space-between">
        <Grid item xs={12} sm={2}>
          <Button variant="contained" onClick={handleCreateChat} sx={{ backgroundColor: "rgb(64, 173, 228)", "&:hover": { backgroundColor: "rgb(52, 143, 187)" } }}>
            Create New Article
          </Button>
        </Grid>
        <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem sx={{ my: 2 }} />
        <Grid item xs={12} sm={9}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: "#343542", color: "white" }}>Title</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: "#343542", color: "white" }}>Article Type</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: "#343542", color: "white" }}>Created Date</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: "#343542", color: "white" }}>Last Used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((article) => (
                  <TableRow key={article.id} hover onClick={() => redirectToAiChat(article.id)} sx={{ '&:hover .MuiIconButton-root': { visibility: 'visible' }, '.MuiIconButton-root': { visibility: 'hidden' } }}>
                    <TableCell align="center">
                      <EditableTitleCell
                        articleId={article.id}
                        initialValue={article.name}
                        onUpdate={handleUpdateArticleTitle}
                      />
                    </TableCell>
                    <TableCell align="center">{article.product || "NA"}</TableCell>
                    <TableCell align="center">{article.created_date ? format(parseISO(article.created_date), 'PPP') : "NA"}</TableCell>
                    <TableCell align="center">{formatDistanceToNow(parseISO(article.modified_date), { addSuffix: true })}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={articles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default IndexPage;
