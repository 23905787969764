import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Box from "@mui/material/Box";
import toast from 'react-hot-toast';
import LinearProgress from "@mui/material/LinearProgress";
import AdvancedOptions from "../reusableComponent/AdvancedOptions";
import AiChatTools from "./aiChatTools";
import { styled } from '@mui/material/styles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const languageMap = {
  "English (British English)": "en-GB",
  "English (American)": "en-US",
  "Mandarin Chinese": "zh-CN",
  "Spanish": "es-ES",
  "Hindi-Urdu": "hi-IN",
  "Arabic": "ar-SA",
  "Bengali": "bn-BD",
  "Portuguese (European)": "pt-PT",
  "Russian": "ru-RU",
  "Indonesian": "id-ID",
  "French (Standard)": "fr-FR",
  "French (Quebec)": "fr-CA",
};

const PromptContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E0E0",
  borderRadius: "16px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  boxShadow: "0px 3px 6px #00000029",
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  marginLeft: "8px",
  padding: "8px",
  backgroundColor: "#24ADE4",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#1c8dbf",
  },
}));

const AiChatSelection = ({ input, setInput, aiChatMessage, setAiChatMessage, handleSendMessage }) => {
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [interimTranscript, setInterimTranscript] = useState("");
  const [uploading, setUploading] = useState(false); // State for file upload progress
  const [selectedTool, setSelectedTool] = useState(null); // State for selected tool
  const [placeholder, setPlaceholder] = useState("Type or speak your message..."); // State for placeholder text
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileUploadClick = () => {
    document.getElementById('file-upload').click();
    handleMenuClose();
  };

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const speechRecognition = new window.webkitSpeechRecognition();
      speechRecognition.continuous = false;
      speechRecognition.interimResults = true;

      const languageCode = languageMap[input.settings.language] || "en-GB";
      speechRecognition.lang = languageCode;

      speechRecognition.onresult = (event) => {
        let interimText = "";
        let finalText = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalText += transcript;
          } else {
            interimText += transcript;
          }
        }
        setInterimTranscript(interimText);
        if (finalText) {
          setAiChatMessage(prevMessage => prevMessage + finalText);
        }
      };

      speechRecognition.onerror = (event) => {
        toast.error(event.error); // Display error with toast
      };

      speechRecognition.onend = () => {
        setIsListening(false);
      };

      setRecognition(speechRecognition);
    } else {
      console.warn("Web Speech API is not supported in this browser.");
      toast.error("Web Speech API is not supported in this browser."); // Display error with toast
    }
  }, [input.settings.language]);

  useEffect(() => {
    // Update placeholder based on the selected tool
    if (selectedTool) {
      switch (selectedTool.value) {
        case 'googleSearch':
          setPlaceholder("Type your search query...");
          break;
        case 'crawlAndExtractTextFromWebsite':
          setPlaceholder("Enter the website URL...");
          break;
        case 'generateImageResponse':
          setPlaceholder("Describe the image you want to generate...");
          break;
        case 'generateWeatherForecastMessage':
          setPlaceholder("Enter the location for weather forecast...");
          break;
        default:
          setPlaceholder("Type or speak your message...");
      }
    } else {
      setPlaceholder("Type or speak your message...");
    }
  }, [selectedTool]);

  const handleVoiceInput = () => {
    if (recognition) {
      if (isListening) {
        recognition.stop();
      } else {
        setAiChatMessage("");
        recognition.start();
      }
      setIsListening(prevState => !prevState);
    } else {
      toast.error("Speech recognition is not available."); // Display error with toast
    }
  };

  const handleSubmit = (content) => {
    const updatedMessages = [
      ...(input.options.messages || []),
      { role: "user", content: content }
    ];

    let updatedOptions = { ...input.options, messages: updatedMessages };
    if (selectedTool && selectedTool.value !== 'standardChat') {
      updatedOptions = { ...updatedOptions, tool_choice: { type: "function", function: { name: selectedTool.function.name } } };
    } else {
      updatedOptions = { ...updatedOptions, tool_choice: 'auto' };
    }

    setInput(prevInput => ({
      ...prevInput,
      options: updatedOptions,
    }));

    handleSendMessage({
      ...input,
      options: updatedOptions,
    });

    setAiChatMessage("");
    setInterimTranscript(""); // Clear the interim transcript
    setSelectedTool(null); // Reset selected tool
  };

  // Handles the Enter key press for form submission or new line
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        // If Shift + Enter is pressed, add a new line
        event.preventDefault();
        setAiChatMessage(aiChatMessage + "\n");
      } else {
        // If only Enter is pressed, submit the form
        event.preventDefault();
        handleSubmit(aiChatMessage);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // File size limit of 3MB
        const fileSizeLimit = 3 * 1024 * 1024;
        // Allowed file types
        const allowedFileTypes = [".csv", ".xls", ".xlsx", ".doc", ".docx", ".txt"];

        const fileType = file.name.split('.').pop().toLowerCase();
        if (!allowedFileTypes.includes(`.${fileType}`)) {
          toast.error("Unsupported file type. Allowed types: .csv, .xls, .xlsx, .doc, .docx, .txt");
          return;
        }

        if (file.size > fileSizeLimit) {
          toast.error("File size exceeds the 2MB limit.");
          return;
        }

        setUploading(true);
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const fileContent = e.target.result;
            if (!fileContent) {
              throw new Error("File content is empty or could not be read.");
            }

            handleSubmit(fileContent);
          } catch (innerError) {
            toast.error("An error occurred while processing the file content.");
            console.error("File content processing error:", innerError);
          } finally {
            setUploading(false);
          }
        };
        reader.onerror = () => {
          toast.error("Error reading file");
          setUploading(false);
        };
        reader.readAsText(file);
      } catch (error) {
        toast.error("An unexpected error occurred during file upload.");
        console.error("File upload error:", error);
        setUploading(false);
      }
    }
  };


  return (
    <div className="left-section">
      <div className="filter-section">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="select-box" data-testid="setting-component">
              <h3 className="titlename">AI-Chat</h3>
              <h5>Settings:</h5>
              <AdvancedOptions input={input} setInput={setInput} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <h5>Prompt:</h5>
            <div className="select-box">
              <PromptContainer>
                <AiChatTools selectedTool={selectedTool} onSelectTool={setSelectedTool} /> {/* Tools Dropdown */}
                <input
                  type="file"
                  accept=".csv, .xls, .xlsx, .doc, .docx, .txt"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-upload"
                />
                <IconButtonStyled
                  aria-controls="file-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <AttachFileIcon />
                </IconButtonStyled>
                <Menu
                  id="file-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleFileUploadClick}>
                    <FileUploadIcon sx={{ marginRight: 1 }} />
                    Upload from Computer
                  </MenuItem>
                </Menu>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    position: "relative",
                  }}
                >
                  <TextField
                    id="aiChatMessage"
                    variant="standard"
                    fullWidth
                    multiline
                    rows={4}
                    value={aiChatMessage + interimTranscript}
                    onChange={e => setAiChatMessage(e.target.value)}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: aiChatMessage ? "#000" : "#aaa",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    inputProps={{ "data-testid": "article-prompt" }}
                  />
                  <IconButton
                    data-testid="mic-button"
                    color="inherit"
                    onClick={handleVoiceInput}
                    sx={{
                      padding: "8px",
                      backgroundColor: isListening ? "#d32f2f" : "#24ADE4",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: isListening ? "#b71c1c" : "#1c8dbf",
                      },
                      position: "absolute",
                      right: "8px",
                    }}
                  >
                    <MicIcon />
                  </IconButton>
                </Box>
                <IconButtonStyled
                  data-testid="send-button"
                  color="inherit"
                  onClick={() => handleSubmit(aiChatMessage)}
                  disabled={!aiChatMessage.trim()}
                >
                  <SendIcon />
                </IconButtonStyled>
              </PromptContainer>
              {uploading && <LinearProgress />}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AiChatSelection;
