import toast, { toast as toastDismiss } from 'react-hot-toast';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Button, Grid, TextField, FormControlLabel, Switch, Typography, Tooltip, Autocomplete, Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import { isFuture } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { userProfileContext } from '../../lib/UserProvider';
import usersApi from '../../server/usersApi';
import {
  TASK_STATUS,
  RECURRENCE_RULE_OPTIONS,
  DATE_RANGES,
  ORDER_BY_OPTIONS,
  ARTICLE_TYPES,
  TASK_TYPES,
  calculateNextScheduledTime,
  fetchMessageDetails,
  generateHistoryLinks
} from './helpers';

// Local Definitions from 'definitions' file
import {
  articleTypes,
  languages,              // Predefined set of language options
  lengths,                // Predefined set of length units
  temperatures,           // Predefined set of temperature units
  tones,                  // Predefined set of tones
  teams,                  // Predefined set of teams
  teamServiceTypeMapping, // Predefined set of services by team
  serviceTypes,           // Predefined set of service types
  models,                 // Predefined set of AI models
  publications,           // Predefined set of publication types
  printPublications,    // Predefined set of print publications
  empty,                   // Definition for an 'empty' state or selection
  booleans,
  publicationStatusOptions
} from "../../definitions";


const CreateTaskPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user_id: userProfileId } = useContext(userProfileContext) || {};
  const [isNewArticleOptionsExpanded, setIsNewArticleOptionsExpanded] = useState(true);
  const [isAiOptionsExpanded, setIsAiOptionsExpanded] = useState(true);
  const [isPublicNoticesOptionsVisible, setIsPublicNoticesOptionsVisible] = useState(false);
  const [isUniqueArticleOptionsVisible, setIsUniqueArticleOptionsVisible] = useState(false);
  const [isWeatherArticleOptionsVisible, setIsWeatherArticleOptionsVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const toastShownRef = useRef(false);

  const calculateInitialScheduledTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 5);
    return now;
  };

  // Define state for storing form input values
  const [task, setTask] = useState({
    id: 0,
    status: TASK_STATUS.PENDING,
    output: null,
    name: "",
    type: TASK_TYPES[0],
    created_at: "",
    updated_at: "",
    scheduled_time: calculateInitialScheduledTime(),
    user_id: userProfileId,
    input: {
      additionalData: {
        search: "",
        publications: [],
        dateRange: DATE_RANGES[3].value,
        category: ARTICLE_TYPES[0].value,
        orderBy: ORDER_BY_OPTIONS[0].value,
      },
      cmsData: {
        cms: "",
        cmsEntries: [],
        cmsPublicationStatus: "draft",
        cmsContentType: articleTypes[15].value,
        cmsConfig: {}
      },
      options: {
        model: models[4].value,
        temperature: temperatures[4].value,
        max_length: lengths[1].value,
        messages: [],
        user: userProfileId,
      },
      settings: {
        serviceType: serviceTypes[26].value,
        language: languages[0].value,
        articleTone: tones[0].value,
        writingStyle: "",
      }
    },
    recurring: false,
    recurrence_rule: RECURRENCE_RULE_OPTIONS.NONE,
    last_run_successful: null,
    last_run_at: "",
    next_run_at: "",
    auto_publish: "false"
  });

  useEffect(() => {
    setIsPublicNoticesOptionsVisible(task?.type?.includes('Public Notices'));
    setIsUniqueArticleOptionsVisible(task?.type === 'Unique Article');
    setIsWeatherArticleOptionsVisible(task?.type === 'Weather Article');
  }, [task.type]);

  useEffect(() => {
    if (!toastShownRef.current && task?.output?.message) {
      if (task?.status === TASK_STATUS.COMPLETED || task?.last_run_successful === true) {
        toast.success('Last ' + task.output.message, {
          duration: 5000,
        });
      } else if (task?.status === TASK_STATUS.FAILED || task?.last_run_successful === false) {
        toast.error('Last Task ' + task.output.message, {
          duration: 5000,
        });
      }
      toastShownRef.current = true;
    }

    // Cleanup function to dismiss all toasts on unmount
    return () => {
      toastDismiss.dismiss();
    };
  }, [task?.status, task?.output?.message, task?.last_run_successful]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const statusOptions = Object.entries(TASK_STATUS).map(([key, value]) => ({ label: value, value }));
  const recurrenceOptions = Object.entries(RECURRENCE_RULE_OPTIONS).map(([key, value]) => ({ label: value, value: value }));

  const handleNewArticleOptionsToggle = () => {
    setIsNewArticleOptionsExpanded(prevState => !prevState);
  };

  const handleAiOptionsToggle = () => {
    setIsAiOptionsExpanded(prevState => !prevState);
  };

  const validateFields = () => {
    const errors = {};
    if (!task?.name) errors.name = 'Task name is required';
    if (!task?.type) errors.type = 'Task type is required';
    if (!task?.status) errors.status = 'Task status is required';
    if (!task?.recurrence_rule) errors.recurrence_rule = 'Recurrence rule is required';
    if (!task?.scheduled_time) errors.scheduled_time = 'Scheduled start time is required';
    if (!task?.input?.cmsData?.cmsEntries?.length) errors.cmsEntries = 'CMS is required';
    if (!task?.input?.cmsData?.cmsContentType) errors.cmsContentType = 'Article type is required';
    if (!task?.input?.cmsData?.cmsPublicationStatus) errors.cmsPublicationStatus = 'Publication status is required';

    if (task?.type?.includes('Public Notices')) {
      if (!task?.input?.additionalData?.publications?.length) errors.publications = 'At least one publication is required for Public Notices';
      if (!task?.input?.additionalData?.dateRange) errors.dateRange = 'Date range is required for Public Notices';
      if (!task?.input?.additionalData?.orderBy) errors.orderBy = 'Order by is required for Public Notices';
      if (!task?.input?.additionalData?.category) errors.category = 'Category is required for Public Notices';
    }

    if (task?.type === 'Unique Article') {
      if (!task?.input?.options?.messages?.length) errors.messages = 'Article prompt is required for Unique Article';
    }

    if (task?.type === 'Weather Article') {
      if (!task?.input?.additionalData?.publications?.length) errors.publications = 'At least one publication is required for Weather Notices';
    }

    return errors;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    try {
      if (location.state && location.state.task && location.state.task.id) {
        const updatedTask = {
          ...task,
          output: null,
          progress: 0
        };
        await usersApi.updateUserTask(userProfileId, location.state.task.id, updatedTask);
        toast.success('Task updated successfully!');
      } else {
        await usersApi.createTask(userProfileId, task);
        toast.success('Task created successfully!');
      }
      navigate(`/tasks/`);
    } catch (error) {
      console.error('Failed to save task:', error);
      toast.error('Failed to save task. Please try again.');
    }
  };

  // Load task data from location state when component mounts
  useEffect(() => {
    if (location.state && location.state.task) {
      const data = location.state.task;
      setTask(prevTask => {
        try {
          return {
            ...prevTask,
            ...data,
            input: JSON.parse(data.input || '{}'),
            output: JSON.parse(data.output || '{}'),
            scheduled_time: prevTask?.scheduled_time ?? calculateInitialScheduledTime()
          };
        } catch (error) {
          console.error('Error parsing JSON:', error);
          // Fallback: use default values or keep previous input state
          return {
            ...prevTask,
            ...data,
            scheduled_time: calculateInitialScheduledTime()
          };
        }
      });
    } else if (location.state && location.state.input) {
      setTask(prevTask => ({
        ...prevTask,
        type: location?.state?.type || prevTask.type,
        input: { ...prevTask.input, ...location.state.input }
      }));
    }
  }, [location.state]);

  // Function to handle updating task state including arrays for multi-select
  const handleUpdateTask = (field, value, nestedField = null) => {
    setTask(prevTask => {
      if (nestedField) {
        let updatedInput = { ...prevTask.input };
        const updatedNested = {
          ...prevTask.input[nestedField],
          [field]: value
        };

        // Handle array updates specifically for multi-select fields like 'publications'
        if (Array.isArray(value)) {
          updatedNested[field] = value.map(item => item.printTitle || item.value);
        }

        // Reset the dependent field if 'articleTone' or 'writingStyle' is changed
        if (field === 'writingStyle') {
          updatedNested.articleTone = '';  // Reset articleTone when writingStyle is set
        } else if (field === 'articleTone') {
          updatedNested.writingStyle = ''; // Reset writingStyle when articleTone is set
        }

        // If updating CMS, also update associated print publications
        if (field === 'cmsEntries') {
          const selectedUrls = value.map(item => item.url);
          const associatedPrintPublications = printPublications.filter(pub =>
            selectedUrls.includes(pub.url)
          );
          updatedInput.additionalData.publications = associatedPrintPublications.map(pub => pub.printTitle);
        }

        // Handle array updates specifically for the 'messages' field
        if (field === 'messages') {
          const userMessage = { role: 'user', content: value };
          updatedNested.messages = [userMessage];
        }

        return {
          ...prevTask,
          input: {
            ...updatedInput,
            [nestedField]: updatedNested
          }
        };
      } else if (field in prevTask.input) {
        // Handle updates directly within the 'input' object
        const updatedInput = {
          ...prevTask.input,
          [field]: value
        };

        return {
          ...prevTask,
          input: updatedInput
        };
      } else {
        const updatedTask = {
          ...prevTask,
          [field]: value
        };

        // Reset relevant fields based on the new task type
        if (field === 'type') {
          // Reset Public Notices options
          if (value.includes('Public Notices')) {
            updatedTask.input.settings.serviceType = serviceTypes[26].value;
            //updatedTask.input.additionalData.publications = [];
            updatedTask.input.additionalData.dateRange = DATE_RANGES[3].value;
            updatedTask.input.additionalData.category = ARTICLE_TYPES[0].value;
            updatedTask.input.additionalData.orderBy = ORDER_BY_OPTIONS[0].value;
          } else {
            // Reset values for other types
            //updatedTask.input.additionalData.publications = [];
            updatedTask.input.additionalData.dateRange = '';
            updatedTask.input.additionalData.category = '';
            updatedTask.input.additionalData.orderBy = '';
          }

          // Reset Unique Article options
          if (value === 'Unique Article') {
            updatedTask.input.settings.serviceType = serviceTypes[20].value;
            updatedTask.input.options.messages = [];
          } else {
            // Reset values for other types
            updatedTask.input.options.messages = [];
          }

          // Reset Weather Article options
          if (value === 'Weather Article') {
            updatedTask.input.settings.serviceType = serviceTypes[22].value;
            updatedTask.input.additionalData.publications = [];
          } else {
            // Reset values for other types
            //updatedTask.input.additionalData.publications = [];
          }

          // Auto-set scheduled start time to current time plus 5 minutes
          updatedTask.scheduled_time = calculateInitialScheduledTime();
        }

        // Handle scheduled_time updates
        if (field === 'scheduled_time') {
          const isScheduledTimeInFuture = isFuture(new Date(value));
          if (updatedTask.recurrence_rule === RECURRENCE_RULE_OPTIONS.NONE) {
            if (isScheduledTimeInFuture) {
              updatedTask.next_run_at = value;
              updatedTask.status = TASK_STATUS.PENDING;
            }
          } else {
            updatedTask.next_run_at = value;
            updatedTask.status = TASK_STATUS.TIMED;
          }
        }

        // Handle recurrence_rule updates
        if (field === 'recurrence_rule') {
          updatedTask.recurring = value !== RECURRENCE_RULE_OPTIONS.NONE;
          if (value !== RECURRENCE_RULE_OPTIONS.NONE) {
            updatedTask.status = TASK_STATUS.TIMED;
            const now = new Date();
            updatedTask.scheduled_time = now;
            updatedTask.next_run_at = calculateNextScheduledTime(now, value);
          } else if (value === RECURRENCE_RULE_OPTIONS.NONE && updatedTask.status === TASK_STATUS.TIMED) {
            updatedTask.status = TASK_STATUS.DISABLED;
            updatedTask.scheduled_time = null;
            updatedTask.next_run_at = null;
          }
        }

        return updatedTask;
      }
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} to="/tasks" color="inherit">
              Tasks
            </Link>
            <Typography color="textPrimary">{location.state && location.state.task ? 'Edit Task' : 'Create Task'}</Typography>
          </Breadcrumbs>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>{'Task Management'}</Typography>{task?.output?.messages?.length > 0 && (
              <Grid item>
                <Tooltip title="View Generated Articles">
                  <IconButton
                    color="primary"
                    onClick={async () => {
                      const sortedMessages = await fetchMessageDetails(task.output.messages, userProfileId);
                      toast.custom((t) => generateHistoryLinks(sortedMessages, t.id), {
                        duration: 90000,
                      });
                    }}
                  >
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Typography variant="subtitle1" gutterBottom>
            Fill in the details below to {location.state && location.state.task ? 'edit' : 'create'} a task.
          </Typography>
          <form onSubmit={handleSubmit}>

            {/* Create / Edit Task */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Tooltip title="Enter the name of the task">
                  <TextField
                    size="small"
                    label="Task Name"
                    variant="outlined"
                    fullWidth
                    value={task.name}
                    onChange={(e) => handleUpdateTask('name', e.target.value)}
                    error={!!validationErrors.name}
                    helperText={validationErrors.name}
                    required
                  />
                </Tooltip>
              </Grid>

              {/* Multi-select Autocomplete for Task Type */}
              <Grid item xs={12} md={6}>
                <Tooltip title="Select one or more task types">
                  <Autocomplete
                    size="small"
                    value={task.type}
                    onChange={(event, newValue) => handleUpdateTask('type', newValue)}
                    options={TASK_TYPES}
                    getOptionLabel={(option) => option} // Assuming the option is a simple string
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Task Type"
                        fullWidth
                        error={!!validationErrors.type}
                        helperText={validationErrors.type}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Task Status */}
              <Grid item xs={12} md={6}>
                <Tooltip title="Select the status of the task">
                  <Autocomplete
                    size="small"
                    value={statusOptions.find(status => status.value === task.status)}
                    onChange={(event, newValue) => handleUpdateTask('status', newValue ? newValue.value : '')}
                    options={statusOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Task Status"
                        fullWidth
                        error={!!validationErrors.status}
                        helperText={validationErrors.status}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Task Recurrence Rule */}
              <Grid item xs={12} md={6}>
                <Tooltip title="Select the recurrence rule for the task">
                  <Autocomplete
                    size="small"
                    value={recurrenceOptions.find(option => option.value === task.recurrence_rule) || null}
                    onChange={(event, newValue) => {
                      handleUpdateTask('recurrence_rule', newValue ? newValue.value : RECURRENCE_RULE_OPTIONS.NONE);
                    }}
                    options={recurrenceOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Task Recurrence Rule"
                        fullWidth
                        error={!!validationErrors.recurrence_rule}
                        helperText={validationErrors.recurrence_rule}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Start Date */}
              <Grid item xs={12}>
                <Tooltip title="Select the scheduled start time for the task">
                  <DateTimePicker
                    label="Scheduled Start"
                    value={task.scheduled_time || null}
                    onChange={(newValue) => handleUpdateTask('scheduled_time', newValue)}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={!!validationErrors.scheduled_time}
                        helperText={validationErrors.scheduled_time}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Add the CMS Autocomplete field for multiple selections */}
              <Grid item xs={12} md={6}>
                <Tooltip title="Select one or more CMS publications">
                  <Autocomplete
                    multiple
                    size="small"
                    value={publications.filter(pub => task?.input?.cmsData?.cmsEntries?.includes(pub.value))}
                    onChange={(event, newValue) => handleUpdateTask('cmsEntries', newValue, 'cmsData')}
                    options={publications}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="CMS"
                        fullWidth
                        error={!!validationErrors.cmsEntries}
                        helperText={validationErrors.cmsEntries}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Article Type */}
              <Grid item xs={12} md={6}>
                <Tooltip title="Select the article type">
                  <Autocomplete
                    size="small"
                    value={articleTypes.find(type => type.value === task?.input?.cmsData?.cmsContentType) || null}
                    onChange={(event, newValue) => handleUpdateTask('cmsContentType', newValue ? newValue.value : '', 'cmsData')}
                    options={articleTypes}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Article Type"
                        fullWidth
                        error={!!validationErrors.cmsContentType}
                        helperText={validationErrors.cmsContentType}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Publication Status */}
              <Grid item xs={12} md={6}>
                <Tooltip title="Select the publication status">
                  <Autocomplete
                    size="small"
                    value={publicationStatusOptions.find(status => status.value === task?.input?.cmsData?.cmsPublicationStatus) || null}
                    onChange={(event, newValue) => handleUpdateTask('cmsPublicationStatus', newValue ? newValue.value : 'Draft', 'cmsData')}
                    options={publicationStatusOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Publication Status"
                        fullWidth
                        error={!!validationErrors.cmsPublicationStatus}
                        helperText={validationErrors.cmsPublicationStatus}
                        required
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              {/* Push to CMS */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={task.auto_publish === 'true'} onChange={(e) => handleUpdateTask('auto_publish', e.target.checked ? 'true' : 'false')} />}
                  label="Push to CMS"
                  labelPlacement="start"
                />
              </Grid>

              {/* Public Notices Options */}
              {isPublicNoticesOptionsVisible && (
                <Grid item xs={12}>
                  <Accordion
                    expanded={isNewArticleOptionsExpanded}
                    onChange={handleNewArticleOptionsToggle}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" gutterBottom>Public Notices Article Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Select one or more Print publications">
                            <Autocomplete
                              multiple
                              size="small"
                              value={printPublications.filter(pub => (task?.input?.additionalData?.publications || []).includes(pub.printTitle))}
                              onChange={(event, newValue) => handleUpdateTask('publications', newValue, 'additionalData')}
                              options={printPublications}
                              getOptionLabel={(option) => option.printTitle}
                              isOptionEqualToValue={(option, value) => option.printTitle === value.printTitle}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Print Publications"
                                  fullWidth
                                  error={!!validationErrors.publications}
                                  helperText={validationErrors.publications}
                                  required
                                />
                              )}
                            />
                          </Tooltip>
                        </Grid>
                        {/* Date Range */}
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Select the date range">
                            <Autocomplete
                              size="small"
                              value={DATE_RANGES.find(range => range.value === task?.input?.additionalData?.dateRange) || null}
                              onChange={(event, newValue) => handleUpdateTask('dateRange', newValue ? newValue.value : '', 'additionalData')}
                              options={DATE_RANGES}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Date Range"
                                  fullWidth
                                  error={!!validationErrors.dateRange}
                                  helperText={validationErrors.dateRange}
                                  required
                                />
                              )}
                            />
                          </Tooltip>
                        </Grid>

                        {/* Results Order */}
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Select how you want the results ordered">
                            <Autocomplete
                              size="small"
                              value={ORDER_BY_OPTIONS.find(option => option.value === task?.input?.additionalData?.orderBy) || null}
                              onChange={(event, newValue) => handleUpdateTask('orderBy', newValue ? newValue.value : '', 'additionalData')}
                              options={ORDER_BY_OPTIONS}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Order By"
                                  fullWidth
                                  error={!!validationErrors.orderBy}
                                  helperText={validationErrors.orderBy}
                                  required
                                />
                              )}
                            />
                          </Tooltip>
                        </Grid>

                        {/* PNP Article Type */}
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Select the article Category">
                            <Autocomplete
                              size="small"
                              value={ARTICLE_TYPES.find(type => type.value === task?.input?.additionalData?.category) || null}
                              onChange={(event, newValue) => handleUpdateTask('category', newValue ? newValue.value : '', 'additionalData')}
                              options={ARTICLE_TYPES}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Article Category"
                                  fullWidth
                                  error={!!validationErrors.category}
                                  helperText={validationErrors.category}
                                  required
                                />
                              )}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}

              {/* Unique Article Options */}
              {isUniqueArticleOptionsVisible && (
                <Grid item xs={12}>
                  <Accordion
                    expanded={isNewArticleOptionsExpanded}
                    onChange={handleNewArticleOptionsToggle}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" gutterBottom>Unique Article Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={12}>
                        <Grid item xs={12}>
                          <Tooltip title="Enter the article prompt">
                            <TextField
                              size="small"
                              label="Article Prompt"
                              variant="outlined"
                              fullWidth
                              value={task?.input?.options?.messages?.[0]?.content || ''}
                              onChange={(event) => handleUpdateTask('messages', event.target.value, 'options')}
                              error={!!validationErrors.messages}
                              helperText={validationErrors.messages}
                              required
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}

              {/* Weather Article Options */}
              {isWeatherArticleOptionsVisible && (
                <Grid item xs={12}>
                  <Accordion
                    expanded={isNewArticleOptionsExpanded}
                    onChange={handleNewArticleOptionsToggle}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" gutterBottom>Weather Article Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Tooltip title="Select one or more Print publications">
                            <Autocomplete
                              multiple
                              size="small"
                              value={printPublications.filter(pub => (task?.input?.additionalData?.publications || []).includes(pub.printTitle))}
                              onChange={(event, newValue) => handleUpdateTask('publications', newValue, 'additionalData')}
                              options={printPublications}
                              getOptionLabel={(option) => option.printTitle}
                              isOptionEqualToValue={(option, value) => option.printTitle === value.printTitle}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Print Publications"
                                  fullWidth
                                  error={!!validationErrors.publications}
                                  helperText={validationErrors.publications}
                                  required
                                />
                              )}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}


              {/* New AI Options section */}
              <Grid item xs={12}>
                <Accordion
                  expanded={isAiOptionsExpanded}
                  onChange={handleAiOptionsToggle}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" gutterBottom>AI Options</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>

                      {/* Service Type */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the Service Type">
                          <Autocomplete
                            size="small"
                            value={serviceTypes.find(option => option.value === task?.input?.settings?.serviceType) || null}
                            onChange={(event, newValue) => handleUpdateTask('serviceType', newValue ? newValue.value : '', 'settings')}
                            options={serviceTypes}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Services" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>

                      {/* Language selection */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the language">
                          <Autocomplete
                            size="small"
                            value={languages.find(option => option.value === task?.input?.settings?.language) || null}
                            onChange={(event, newValue) => handleUpdateTask('language', newValue ? newValue.value : '', 'settings')}
                            options={languages}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Language" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>

                      {/* Model selection */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the model">
                          <Autocomplete
                            size="small"
                            value={models.find(option => option.value === task?.input?.options?.model) || null}
                            onChange={(event, newValue) => handleUpdateTask('model', newValue ? newValue.value : '', 'options')}
                            options={models}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Model" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>

                      {/* Temperature selection */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the temperature">
                          <Autocomplete
                            size="small"
                            value={temperatures.find(option => option.value === task?.input?.options?.temperature) || null}
                            onChange={(event, newValue) => handleUpdateTask('temperature', newValue ? newValue.value : '', 'options')}
                            options={temperatures}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Temperature" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>

                      {/* Length selection */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the length">
                          <Autocomplete
                            size="small"
                            value={lengths.find(option => option.value === task?.input?.options?.max_length) || null}
                            onChange={(event, newValue) => handleUpdateTask('max_length', newValue ? newValue.value : 500, 'options')}
                            options={lengths}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Length" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>

                      {/* Tone selection */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the tone">
                          <Autocomplete
                            size="small"
                            value={tones.find(option => option.value === task?.input?.settings?.articleTone) || null}
                            onChange={(event, newValue) => handleUpdateTask('articleTone', newValue ? newValue.value : '', 'settings')}
                            options={tones}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Tone" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>

                      {/* Publication Tone selection */}
                      <Grid item xs={12} md={6}>
                        <Tooltip title="Select the publication tone">
                          <Autocomplete
                            size="small"
                            value={publications.find(option => option.value === task?.input?.settings?.writingStyle) || null}
                            onChange={(event, newValue) => handleUpdateTask('writingStyle', newValue ? newValue.value : '', 'settings')}
                            options={publications}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField {...params} label="Publication Tone" fullWidth />
                            )}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* Add more fields for other task properties if needed */}
              <Grid item xs={12}>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  {location.state && location.state.task ? 'Update Task' : 'Create Task'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid >
    </LocalizationProvider >
  );
};

export default CreateTaskPage;
