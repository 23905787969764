export const publications = [
  {
    "label": "Aberdeen Live",
    "value": "aberdeenLive",
    "url": "https://www.aberdeenlive.news/"
  },
  {
    "label": "Bedfordshire Live",
    "value": "bedfordshirelive",
    "url": "https://www.bedfordshirelive.co.uk/"
  },
  {
    "label": "Belfast Live",
    "value": "belfastlive",
    "url": "https://www.belfastlive.co.uk/"
  },
  {
    "label": "Berkshire Live",
    "value": "getreading",
    "url": "https://www.getreading.co.uk/"
  },
  {
    "label": "Birmingham Live",
    "value": "birminghammail",
    "url": "https://www.birminghammail.co.uk/"
  },
  {
    "label": "Bristol Live",
    "value": "bristolpost",
    "url": "https://www.bristolpost.co.uk/"
  },
  {
    "label": "Buckinghamshire Live",
    "value": "buckinghamshirelive",
    "url": "https://www.buckinghamshirelive.com/"
  },
  {
    "label": "Business Insider",
    "value": "businessInsider",
    "url": "https://www.insider.co.uk/"
  },
  {
    "label": "Business Live",
    "value": "birminghampost",
    "url": "https://www.business-live.co.uk/"
  },
  {
    "label": "Buzz",
    "value": "buzz",
    "url": "https://www.buzz.ie/"
  },
  {
    "label": "Cambridgeshire Live",
    "value": "cambridgenews",
    "url": "https://www.cambridge-news.co.uk/"
  },
  {
    "label": "Cheshire Live",
    "value": "chesterchronicle",
    "url": "https://www.cheshire-live.co.uk/"
  },
  {
    "label": "Chronicle Live",
    "value": "nechronicle",
    "url": "https://www.chroniclelive.co.uk/"
  },
  {
    "label": "Cork Beo",
    "value": "corklive",
    "url": "https://www.corkbeo.ie/"
  },
  {
    "label": "Cornwall Live",
    "value": "cornwalllive",
    "url": "https://www.cornwalllive.com/"
  },
  {
    "label": "Coventry Live",
    "value": "coventrytelegraph",
    "url": "https://www.coventrytelegraph.net/"
  },
  {
    "label": "Curiously",
    "value": "curiously",
    "url": "https://www.curiously.co.uk/"
  },
  {
    "label": "Daily Express",
    "value": "express",
    "url": "https://www.express.co.uk/"
  },
  {
    "label": "Daily Express US",
    "value": "expressus",
    "url": "https://www.the-express.com/"
  },
  {
    "label": "Daily Record",
    "value": "dailyrecord",
    "url": "https://www.dailyrecord.co.uk/"
  },
  {
    "label": "Daily Star",
    "value": "dailystar",
    "url": "https://www.dailystar.co.uk/"
  },
  {
    "label": "Derbyshire Live",
    "value": "derbytelegraph",
    "url": "https://www.derbytelegraph.co.uk/"
  },
  {
    "label": "Devon Live",
    "value": "devonlive",
    "url": "https://www.devonlive.com/"
  },
  {
    "label": "Dorset Live",
    "value": "dorsetlive",
    "url": "https://www.dorset.live/"
  },
  {
    "label": "Dublin Live",
    "value": "dublinlive",
    "url": "https://www.dublinlive.ie/"
  },
  {
    "label": "Edinburgh Live",
    "value": "edinburghlive",
    "url": "https://www.edinburghlive.co.uk/"
  },
  {
    "label": "Essex Live",
    "value": "essexlive",
    "url": "https://www.essexlive.news/"
  },
  {
    "label": "Football London",
    "value": "footballlondon",
    "url": "https://www.football.london/"
  },
  {
    "label": "Football Scotland",
    "value": "footballScotland",
    "url": "https://www.footballscotland.co.uk/"
  },
  {
    "label": "Galway Beo",
    "value": "galwaybeo",
    "url": "https://www.galwaybeo.ie/"
  },
  {
    "label": "Glasgow Live",
    "value": "glasgowlive",
    "url": "https://www.glasgowlive.co.uk/"
  },
  {
    "label": "Gloucestershire Live",
    "value": "gloucestershirelive",
    "url": "https://www.gloucestershirelive.co.uk/"
  },
  {
    "label": "Grimsby Live",
    "value": "grimsbytelegraph",
    "url": "https://www.grimsbytelegraph.co.uk/"
  },
  {
    "label": "Hampshire Live",
    "value": "hampshirelive",
    "url": "https://www.hampshirelive.news/"
  },
  {
    "label": "Herts Live",
    "value": "hertfordshiremercury",
    "url": "https://www.hertfordshiremercury.co.uk/"
  },
  {
    "label": "Hull Live",
    "value": "hulldailymail",
    "url": "https://www.hulldailymail.co.uk/"
  },
  {
    "label": "In Your Area",
    "value": "inyourarea",
    "url": "https://www.inyourarea.co.uk/"
  },
  {
    "label": "In Your Area - Public Notices",
    "value": "inyourareapn",
    "url": "https://www.inyourarea.co.uk/"
  },
  {
    "label": "Irish Mirror",
    "value": "irishmirror",
    "url": "https://www.irishmirror.ie/"
  },
  {
    "label": "Irish Star",
    "value": "irishstar",
    "url": "https://www.irishstar.com/"
  },
  {
    "label": "Kent Live",
    "value": "kentlive",
    "url": "https://www.kentlive.news/"
  },
  {
    "label": "Lancs Live",
    "value": "accrington",
    "url": "https://www.lancs.live/"
  },
  {
    "label": "Leeds Live",
    "value": "leedslive",
    "url": "https://www.leeds-live.co.uk/"
  },
  {
    "label": "Leicestershire Live",
    "value": "leicestermercury",
    "url": "https://www.leicestermercury.co.uk/"
  },
  {
    "label": "Lincolnshire Live",
    "value": "lincolnshirelive",
    "url": "https://www.lincolnshirelive.co.uk/"
  },
  {
    "label": "Liverpool Echo",
    "value": "liverpoolecho",
    "url": "https://www.liverpoolecho.co.uk/"
  },
  {
    "label": "Liverpool.com",
    "value": "liverpool",
    "url": "https://www.liverpool.com/"
  },
  {
    "label": "Manchester Evening News",
    "value": "men",
    "url": "https://www.manchestereveningnews.co.uk/"
  },
  {
    "label": "Mirror",
    "value": "mirror",
    "url": "https://www.mirror.co.uk/"
  },
  {
    "label": "MyLondon",
    "value": "getwestlondon",
    "url": "https://www.mylondon.news/"
  },
  {
    "label": "Norfolk Live",
    "value": "norfolklive",
    "url": "https://www.norfolklive.co.uk/"
  },
  {
    "label": "North Wales Live",
    "value": "northwales",
    "url": "https://www.dailypost.co.uk/"
  },
  {
    "label": "Northants Live",
    "value": "northantslive",
    "url": "https://www.northantslive.news/"
  },
  {
    "label": "Nottinghamshire Live",
    "value": "nottinghampost",
    "url": "https://www.nottinghampost.com/"
  },
  {
    "label": "OK!",
    "value": "ok",
    "url": "https://www.ok.co.uk/"
  },
  {
    "label": "OK! Beauty Box!",
    "value": "okbb",
    "url": "https://www.ok.co.uk/"
  },
  {
    "label": "Oxfordshire Live",
    "value": "oxfordshirelive",
    "url": "https://www.oxfordshirelive.co.uk/"
  },
  {
    "label": "Plymouth Live",
    "value": "plymouthherald",
    "url": "https://www.plymouthherald.co.uk/"
  },
  {
    "label": "Reach Solutions",
    "value": "reachsolutions",
    "url": "https://www.reachsolutions.co.uk/"
  },
  {
    "label": "RSVP Live",
    "value": "rsvp",
    "url": "https://www.rsvplive.ie/"
  },
  {
    "label": "Scottish Daily Express",
    "value": "scottishdailyexpress",
    "url": "https://www.scottishdailyexpress.co.uk/"
  },
  {
    "label": "Somerset Live",
    "value": "somersetlive",
    "url": "https://www.somersetlive.co.uk/"
  },
  {
    "label": "Staffordshire Live",
    "value": "burtonmail",
    "url": "https://www.staffordshire-live.co.uk/"
  },
  {
    "label": "Stoke on Trent Live",
    "value": "stokesentinel",
    "url": "https://www.stokesentinel.co.uk/"
  },
  {
    "label": "Suffolk Live",
    "value": "suffolklive",
    "url": "https://www.suffolklive.com/"
  },
  {
    "label": "Surrey Live",
    "value": "getsurrey",
    "url": "https://www.getsurrey.co.uk/"
  },
  {
    "label": "Sussex Live",
    "value": "sussexlive",
    "url": "https://www.sussexlive.co.uk/"
  },
  {
    "label": "Teesside Live",
    "value": "gazettelive",
    "url": "https://www.gazettelive.co.uk/"
  },
  {
    "label": "The Mirror",
    "value": "themirror",
    "url": "https://www.themirror.com/"
  },
  {
    "label": "Wales Online",
    "value": "walesonline",
    "url": "https://www.walesonline.co.uk/"
  },
  {
    "label": "Wiltshire Live",
    "value": "wiltshirelive",
    "url": "https://www.wiltshirelive.co.uk/"
  },
  {
    "label": "Yimbly",
    "value": "yimbly",
    "url": "https://yimbly.com/"
  },
  {
    "label": "Yorkshire Live",
    "value": "huddersfieldexaminer",
    "url": "https://www.examinerlive.co.uk/"
  }
];


export const printPublications = [
  {
    "label": "Accrington Observer",
    "value": "accrington",
    "printTitle": "Accrington Observer",
    "printTitleId": "62b4939bdac12f02111fa635",
    "url": "https://www.lancs.live/",
    "tags": [
      "CW:North West",
      "Accrington Observer"
    ],
    "postcode": "BB5 1LJ"
  },
  {
    "label": "Airdrie & Coatbridge Advertiser",
    "value": "dailyrecord",
    "printTitle": "Airdrie & Coatbridge Advertiser",
    "printTitleId": "62b597b474a4f4a20f29cbdf",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:ACA",
      "Airdrie and Coatbridge Advertiser"
    ],
    "postcode": "ML5 3PZ"
  },
  {
    "label": "Aldershot News & Mail Series",
    "value": "getsurrey",
    "printTitle": "Aldershot News & Mail Series",
    "printTitleId": "62b59802dac12f02111fa67f",
    "url": "https://www.getsurrey.co.uk/",
    "tags": [
      "CW:TMS",
      "Aldershot News & Mail"
    ],
    "postcode": "GU11 1HN"
  },
  {
    "label": "Ashbourne News Telegraph",
    "value": "derbytelegraph",
    "printTitle": "Ashbourne News Telegraph",
    "printTitleId": "62b59838016a277c265cb243",
    "url": "https://www.derbytelegraph.co.uk/",
    "tags": [
      "CW:Midlands",
      "Ashbourne News Telegraph"
    ],
    "postcode": "DE6 1GF"
  },
  {
    "label": "Ayrshire Post",
    "value": "dailyrecord",
    "printTitle": "Ayrshire Post",
    "printTitleId": "62b5986f74a4f4a20f29cbf9",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:AYP",
      "Ayrshire Post"
    ],
    "postcode": "KA3 1JD"
  },
  {
    "label": "Bangor & Holyhead Mail",
    "value": "northwales",
    "printTitle": "Bangor & Holyhead Mail",
    "printTitleId": "62c3152bedcea442ed2e6b8b",
    "url": "https://www.dailypost.co.uk/",
    "tags": [
      "CW:North West",
      "Bangor Mail (Holyhead Mail)"
    ],
    "postcode": "LL57 2ED"
  },
  {
    "label": "Bath Chronicle",
    "value": "somersetlive",
    "printTitle": "Bath Chronicle",
    "printTitleId": "62b598a5d849abd9dc0c47c1",
    "url": "https://www.somersetlive.co.uk/",
    "tags": [
      "CW:South West",
      "Bath Chronicle"
    ],
    "postcode": "BA1 1SU"
  },
  {
    "label": "Birmingham Mail",
    "value": "birminghammail",
    "printTitle": "Birmingham Mail",
    "printTitleId": "62b5a3fce8e485262f31f997",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands",
      "Birmingham Mail"
    ],
    "postcode": "B5 4BU"
  },
  {
    "label": "Birmingham Post",
    "value": "birminghammail",
    "printTitle": "Birmingham Post",
    "printTitleId": "62b4934edac12f02111fa62c",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands",
      "Birmingham Post"
    ],
    "postcode": "B5 4BU"
  },
  {
    "label": "Black Country Bugle",
    "value": "birminghammail",
    "printTitle": "Black Country Bugle",
    "printTitleId": "62c3145ed9c111288c0d0149",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands",
      "Black Country Bugle"
    ],
    "postcode": "DY1 4SQ"
  },
  {
    "label": "Blairgowrie Advertiser",
    "value": "dailyrecord",
    "printTitle": "Blairgowrie Advertiser",
    "printTitleId": "62b5a472016a277c265cb284",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:SBA",
      "Blairgowrie Advertiser"
    ],
    "postcode": "PH10 6NQ"
  },
  {
    "label": "Brentwood Gazette",
    "value": "essexlive",
    "printTitle": "Brentwood Gazette",
    "printTitleId": "62b5a559016a277c265cb296",
    "url": "https://www.essexlive.news/",
    "tags": [
      "CW:TMS",
      "Brentwood Gazette"
    ],
    "postcode": "CM15 8AT"
  },
  {
    "label": "Bristol Post",
    "value": "bristolpost",
    "printTitle": "Bristol Post",
    "printTitleId": "62b5a58a74a4f4a20f29cc31",
    "url": "https://www.bristolpost.co.uk/",
    "tags": [
      "CW:South West",
      "Bristol Post"
    ],
    "postcode": "BS1 6EU"
  },
  {
    "label": "Burton Mail",
    "value": "stokesentinel",
    "printTitle": "Burton Mail",
    "printTitleId": "62b5cf40d849abd9dc0c4841",
    "url": "https://www.stokesentinel.co.uk/",
    "tags": [
      "CW:Midlands",
      "Burton Mail"
    ],
    "postcode": "DE14 1PX"
  },
  {
    "label": "Caernarfon Herald",
    "value": "northwales",
    "printTitle": "Caernarfon Herald",
    "printTitleId": "62b5cfb3e8e485262f31f9fa",
    "url": "https://www.dailypost.co.uk/",
    "tags": [
      "CW:North West",
      "CW:clhfslkhflk",
      "Caernarfon Herald"
    ],
    "postcode": "LL55 2DF"
  },
  {
    "label": "Cambridge News",
    "value": "cambridgenews",
    "printTitle": "Cambridge News",
    "printTitleId": "62b5cfe6dac12f02111fa722",
    "url": "https://www.cambridge-news.co.uk/",
    "tags": [
      "CW:TMS",
      "Cambridge News"
    ],
    "postcode": "CB1 2JW"
  },
  {
    "label": "Carmarthen Journal",
    "value": "northwales",
    "printTitle": "Carmarthen Journal",
    "printTitleId": "62b5d019e8e485262f31fa21",
    "url": "https://www.dailypost.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Carmarthen Journal"
    ],
    "postcode": "SA31 1GA"
  },
  {
    "label": "Chester Chronicle",
    "value": "chesterchronicle",
    "printTitle": "Chester Chronicle",
    "printTitleId": "62b5d049d849abd9dc0c487d",
    "url": "https://www.cheshire-live.co.uk/",
    "tags": [
      "CW:North West",
      "Chester Chronicle"
    ],
    "postcode": "CH1 2DY"
  },
  {
    "label": "Chronicle & Informer",
    "value": "getwestlondon",
    "printTitle": "Chronicle & Informer",
    "printTitleId": "62b5d075016a277c265cb2f8",
    "url": "https://www.mylondon.news/",
    "tags": [
      "CW:TMS",
      "Uxbridge Gazette (West London)"
    ],
    "postcode": "TW1 3DU"
  },
  {
    "label": "Cornish Guardian",
    "value": "cornwalllive",
    "printTitle": "Cornish Guardian",
    "printTitleId": "62b5d0a1d849abd9dc0c4894",
    "url": "https://www.cornwalllive.com/",
    "tags": [
      "CW:South West",
      "Cornish Guardian"
    ],
    "postcode": "TR1 2HB"
  },
  {
    "label": "Coventry Telegraph",
    "value": "coventrytelegraph",
    "printTitle": "Coventry Telegraph",
    "printTitleId": "62b5d176e8e485262f31fa51",
    "url": "https://www.coventrytelegraph.net/",
    "tags": [
      "CW:Midlands",
      "Coventry Telegraph"
    ],
    "postcode": "CV1 5EX"
  },
  {
    "label": "Crewe Chronicle",
    "value": "chesterchronicle",
    "printTitle": "Crewe Chronicle",
    "printTitleId": "62b5d1acd849abd9dc0c48c5",
    "url": "https://www.cheshire-live.co.uk/",
    "tags": [
      "CW:North West",
      "Crewe Chronicle"
    ],
    "postcode": "CW1 2DB"
  },
  {
    "label": "Croydon Advertiser",
    "value": "getwestlondon",
    "printTitle": "Croydon Advertiser",
    "printTitleId": "62b5d276dac12f02111fa744",
    "url": "https://www.mylondon.news/",
    "tags": [
      "CW:TMS",
      "Croydon Advertiser"
    ],
    "postcode": "CR0 2NB"
  },
  {
    "label": "Cynon Valley Leader",
    "value": "walesonline",
    "printTitle": "Cynon Valley Leader",
    "printTitleId": "62b5d2a6dac12f02111fa75f",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Cynon Valley Leader"
    ],
    "postcode": "CF43 4RR"
  },
  {
    "label": "Daily Post",
    "value": "northwales",
    "printTitle": "Daily Post",
    "printTitleId": "62b5d2e3d849abd9dc0c48e4",
    "url": "https://www.dailypost.co.uk/",
    "tags": [
      "CW:North West",
      "North Wales Daily Post"
    ],
    "postcode": "LL55 1AF"
  },
  {
    "label": "Daily Record",
    "value": "dailyrecord",
    "printTitle": "Daily Record",
    "printTitleId": "62b5d332dac12f02111fa77d",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:SBA",
      "CW:Central Quay",
      "Daily Record",
      "Sunday Mail"
    ],
    "postcode": "G3 8DA"
  },
  {
    "label": "Derby Telegraph",
    "value": "derbytelegraph",
    "printTitle": "Derby Telegraph",
    "printTitleId": "62b5d36c016a277c265cb357",
    "url": "https://www.derbytelegraph.co.uk/",
    "tags": [
      "CW:Midlands",
      "Derby Telegraph"
    ],
    "postcode": "DE1 2AW"
  },
  {
    "label": "Dorking & Leatherhead Advertiser",
    "value": "getsurrey",
    "printTitle": "Dorking & Leatherhead Advertiser",
    "printTitleId": "62b5d3b0d849abd9dc0c4907",
    "url": "https://www.getsurrey.co.uk/",
    "tags": [
      "CW:TMS",
      "Surrey Mirror"
    ],
    "postcode": "RH4 1AT"
  },
  {
    "label": "Dover Express",
    "value": "kentlive",
    "printTitle": "Dover Express",
    "printTitleId": "62b5d3fa016a277c265cb396",
    "url": "https://www.kentlive.news/",
    "tags": [
      "CW:TMS",
      "Dover Express"
    ],
    "postcode": "CT17 9RH"
  },
  {
    "label": "Dumfries & Galloway Standard",
    "value": "dailyrecord",
    "printTitle": "Dumfries & Galloway Standard",
    "printTitleId": "62b5d431016a277c265cb3d7",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:DSF",
      "Dumfries and Galloway Standard"
    ],
    "postcode": "DG1 2DR"
  },
  {
    "label": "Ealing Gazette Series",
    "value": "getwestlondon",
    "printTitle": "Ealing Gazette Series",
    "printTitleId": "62b5d45de8e485262f31fae4",
    "url": "https://www.mylondon.news/",
    "tags": [
      "CW:TMS",
      "Uxbridge Gazette (West London)"
    ],
    "postcode": "W7 1EU"
  },
  {
    "label": "East Grinstead Courier",
    "value": "kentlive",
    "printTitle": "East Grinstead Courier",
    "printTitleId": "62bc6cc0d849abd9dc0c4d47",
    "url": "https://www.kentlive.news/",
    "tags": [
      "CW:TMS",
      "Kent & Sussex Courier"
    ],
    "postcode": "TN13 1DP"
  },
  {
    "label": "East Kilbride News",
    "value": "dailyrecord",
    "printTitle": "East Kilbride News",
    "printTitleId": "62bc6cf2d9c111288c0ccd15",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:EKN",
      "East Kilbride News"
    ],
    "postcode": "DN15 6PY"
  },
  {
    "label": "Essex Chronicle",
    "value": "essexlive",
    "printTitle": "Essex Chronicle",
    "printTitleId": "62b5d632e8e485262f31fb0c",
    "url": "https://www.essexlive.news/",
    "tags": [
      "CW:TMS",
      "Essex Chronicle"
    ],
    "postcode": "SS14 2NR"
  },
  {
    "label": "Exeter Express & Echo",
    "value": "devonlive",
    "printTitle": "Exeter Express & Echo",
    "printTitleId": "62bdcc2bd9c111288c0ce8ee",
    "url": "https://www.devonlive.com/",
    "tags": [
      "CW:South West",
      "Exeter Express & Echo"
    ],
    "postcode": "EX4 3SB"
  },
  {
    "label": "Folkestone Herald Series",
    "value": "kentlive",
    "printTitle": "Folkestone Herald Series",
    "printTitleId": "62c31486016a277c265cee74",
    "url": "https://www.kentlive.news/",
    "tags": [
      "CW:TMS",
      "Dover Express"
    ],
    "postcode": "CT20 2QW"
  },
  {
    "label": "Galloway News",
    "value": "dailyrecord",
    "printTitle": "Galloway News",
    "printTitleId": "62bdcd57e8e485262f321c83",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:GAL",
      "The Galloway News"
    ],
    "postcode": "DG1 3PF"
  },
  {
    "label": "Glamorgan Gazette",
    "value": "walesonline",
    "printTitle": "Glamorgan Gazette",
    "printTitleId": "62c314cfd9c111288c0d01b7",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Glamorgan Gazette"
    ],
    "postcode": "CF72 8JU"
  },
  {
    "label": "Gloucester Citizen",
    "value": "gloucestershirelive",
    "printTitle": "Gloucester Citizen",
    "printTitleId": "62b5d77f74a4f4a20f29ccf2",
    "url": "https://www.gloucestershirelive.co.uk/",
    "tags": [
      "CW:South West",
      "Gloucester Echo and Citizen"
    ],
    "postcode": "GL1 4DZ"
  },
  {
    "label": "Gloucestershire Echo",
    "value": "gloucestershirelive",
    "printTitle": "Gloucestershire Echo",
    "printTitleId": "62b5d94c74a4f4a20f29cd1b",
    "url": "https://www.gloucestershirelive.co.uk/",
    "tags": [
      "CW:South West",
      "Gloucester Echo and Citizen"
    ],
    "postcode": "GL1 4DZ"
  },
  {
    "label": "Grimsby Telegraph",
    "value": "grimsbytelegraph",
    "printTitle": "Grimsby Telegraph",
    "printTitleId": "62b5d96d74a4f4a20f29cd41",
    "url": "https://www.grimsbytelegraph.co.uk/",
    "tags": [
      "CW:North East",
      "Grimsby Telegraph"
    ],
    "postcode": "DN31 1PG"
  },
  {
    "label": "Gwent Gazette",
    "value": "walesonline",
    "printTitle": "Gwent Gazette",
    "printTitleId": "62c41bab74a4f4a20f2a0ae4",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Gwent Gazette"
    ],
    "postcode": "NP23 6TS"
  },
  {
    "label": "Hamilton Advertiser",
    "value": "dailyrecord",
    "printTitle": "Hamilton Advertiser",
    "printTitleId": "62bebf2f9d3ef96bdbaa3f36",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:HAM",
      "Hamilton Advertiser"
    ],
    "postcode": "ML3 0DA"
  },
  {
    "label": "Hertfordshire Mercury",
    "value": "hertfordshiremercury",
    "printTitle": "Hertfordshire Mercury",
    "printTitleId": "62bebf6c016a277c265cd5b7",
    "url": "https://www.hertfordshiremercury.co.uk/",
    "tags": [
      "CW:TMS",
      "Hertfordshire Mercury"
    ],
    "postcode": "AL7 1RY"
  },
  {
    "label": "Heywood & Middleton Guardian",
    "value": "men",
    "printTitle": "Heywood & Middleton Guardian",
    "printTitleId": "62bebfc997d3ef96bdbaa3f57",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West",
      "Heywood & Middleton Guardian"
    ],
    "postcode": "M24 5LU"
  },
  {
    "label": "Hinckley Times",
    "value": "leicestermercury",
    "printTitle": "Hinckley Times",
    "printTitleId": "62bebfc997d3ef96bdbaa3f57",
    "url": "https://www.leicestermercury.co.uk/",
    "tags": [
      "CW:Midlands",
      "Hinckley Times"
    ],
    "postcode": "LE10 0BG"
  },
  {
    "label": "Huddersfield Daily Examiner",
    "value": "huddersfieldexaminer",
    "printTitle": "Huddersfield Daily Examiner",
    "printTitleId": "62bec009e8e485262f321e00",
    "url": "https://www.examinerlive.co.uk/",
    "tags": [
      "CW:North West",
      "Huddersfield Examiner"
    ],
    "postcode": "HD1 1JB"
  },
  {
    "label": "Hull Daily Mail",
    "value": "hulldailymail",
    "printTitle": "Hull Daily Mail",
    "printTitleId": "62bc7634d849abd9dc0c4dd3",
    "url": "https://www.hulldailymail.co.uk/",
    "tags": [
      "CW:North East",
      "Hull Daily Mail"
    ],
    "postcode": "HU7 0BA"
  },
  {
    "label": "Irvine Herald",
    "value": "dailyrecord",
    "printTitle": "Irvine Herald",
    "printTitleId": "62bec037d9c111288c0ce9e4",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:IHD",
      "Irvine Herald"
    ],
    "postcode": "KA1 2AF"
  },
  {
    "label": "Isle of Thanet Gazette",
    "value": "kentlive",
    "printTitle": "Isle of Thanet Gazette",
    "printTitleId": "62bec071e8e485262f321e00",
    "url": "https://www.kentlive.news/",
    "tags": [
      "CW:TMS",
      "Isle Of Thanet Gazette"
    ],
    "postcode": "CT12 5BQ"
  },
  {
    "label": "Kent & Sussex Courier",
    "value": "kentlive",
    "printTitle": "Kent & Sussex Courier",
    "printTitleId": "62bec0999d3ef96bdbaa4095",
    "url": "https://www.kentlive.news/",
    "tags": [
      "CW:TMS",
      "Kent & Sussex Courier"
    ],
    "postcode": "TN22 1RN"
  },
  {
    "label": "Kilmarnock Standard",
    "value": "dailyrecord",
    "printTitle": "Kilmarnock Standard",
    "printTitleId": "62bec0bf74a4f4a20f29f133",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:KST",
      "Kilmarnock Standard"
    ],
    "postcode": "KA1 2AF"
  },
  {
    "label": "Leek Post & Times",
    "value": "stokesentinel",
    "printTitle": "Leek Post & Times",
    "printTitleId": "62bec0e5e8e485262f322054",
    "url": "https://www.stokesentinel.co.uk/",
    "tags": [
      "CW:Midlands",
      "Leek Post & Times"
    ],
    "postcode": "ST13 5DZ"
  },
  {
    "label": "Leicester Mercury",
    "value": "leicestermercury",
    "printTitle": "Leicester Mercury",
    "printTitleId": "62bec20d74a4f4a20f29f459",
    "url": "https://www.leicestermercury.co.uk/",
    "tags": [
      "CW:Midlands",
      "Leicester Mercury"
    ],
    "postcode": "LE2 0GY"
  },
  {
    "label": "Lennox Herald",
    "value": "dailyrecord",
    "printTitle": "Lennox Herald",
    "printTitleId": "62bec23874a4f4a20f29f5c9",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:LEN",
      "Lennox Herald"
    ],
    "postcode": "G3 8DA"
  },
  {
    "label": "Lincolnshire Echo",
    "value": "lincolnshirelive",
    "printTitle": "Lincolnshire Echo",
    "printTitleId": "62c2eabfe8e485262f32336f",
    "url": "https://www.lincolnshirelive.co.uk/",
    "tags": [
      "CW:North East",
      "Lincolnshire Echo"
    ],
    "postcode": "LN6 3QR"
  },
  {
    "label": "Liverpool Echo",
    "value": "liverpoolecho",
    "printTitle": "Liverpool Echo",
    "printTitleId": "62c2eb0be8e485262f3233c2",
    "url": "https://www.liverpoolecho.co.uk/",
    "tags": [
      "CW:North West",
      "Liverpool Echo"
    ],
    "postcode": "L1 1EQ"
  },
  {
    "label": "Llanelli Star Series",
    "value": "walesonline",
    "printTitle": "Llanelli Star Series",
    "printTitleId": "62c2effae8e485262f32347a",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Llanelli Star"
    ],
    "postcode": "SA15 3UF"
  },
  {
    "label": "Loughborough Echo",
    "value": "leicestermercury",
    "printTitle": "Loughborough Echo",
    "printTitleId": "62c2f057edcea442ed2e68ca",
    "url": "https://www.leicestermercury.co.uk/",
    "tags": [
      "CW:Midlands",
      "Loughborough Echo"
    ],
    "postcode": "LE11 3TU"
  },
  {
    "label": "Macclesfield Express",
    "value": "chesterchronicle",
    "printTitle": "Macclesfield Express",
    "printTitleId": "62c2fb42d9c111288c0cff05",
    "url": "https://www.cheshire-live.co.uk/",
    "tags": [
      "CW:North West",
      "Macclesfield Express"
    ],
    "postcode": "SK11 6TJ"
  },
  {
    "label": "Manchester Evening News",
    "value": "men",
    "printTitle": "Manchester Evening News",
    "printTitleId": "62b4934edac12f02111fa62c",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West",
      "Manchester Evening News"
    ],
    "postcode": "B5 4BU"
  },
  {
    "label": "Manchester Weekly News",
    "value": "men",
    "printTitle": "Manchester Weekly News",
    "printTitleId": "62c2fd53d9c111288c0cff5e",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West",
      "Manchester Weekly News"
    ],
    "postcode": "M2 3AA"
  },
  {
    "label": "Merthyr Express",
    "value": "walesonline",
    "printTitle": "Merthyr Express",
    "printTitleId": "62c2fe0eedcea442ed2e6986",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Merthyr Express"
    ],
    "postcode": "CF47 0AL"
  },
  {
    "label": "Mid Devon Gazette Series",
    "value": "devonlive",
    "printTitle": "Mid Devon Gazette Series",
    "printTitleId": "62c2fe4874a4f4a20f2a05ba",
    "url": "https://www.devonlive.com/",
    "tags": [
      "CW:South West",
      "Tiverton Gazette (Mid Devon Gazette)"
    ],
    "postcode": "EX16 6LU"
  },
  {
    "label": "Mid Somerset Series",
    "value": "somersetlive",
    "printTitle": "Mid Somerset Series",
    "printTitleId": "62c41c9ae8e485262f323a85",
    "url": "https://www.somersetlive.co.uk/",
    "tags": [
      "CW:South West",
      "Mid Somerset Series"
    ],
    "postcode": "BA16 0AD"
  },
  {
    "label": "North Devon Journal",
    "value": "devonlive",
    "printTitle": "North Devon Journal",
    "printTitleId": "62c2fea0016a277c265cec86",
    "url": "https://www.devonlive.com/",
    "tags": [
      "CW:South West",
      "North Devon Journal"
    ],
    "postcode": "LL30 1AB"
  },
  {
    "label": "North Wales Weekly News",
    "value": "northwales",
    "printTitle": "North Wales Weekly News",
    "printTitleId": "62c2ffde74a4f4a20f2a05ba",
    "url": "https://www.dailypost.co.uk/",
    "tags": [
      "CW:North West",
      "North Wales Weekly News"
    ],
    "postcode": "NG7 4HF"
  },
  {
    "label": "Nottingham Post",
    "value": "nottinghampost",
    "printTitle": "Nottingham Post",
    "printTitleId": "62c2ffde74a4f4a20f2a05ba",
    "url": "https://www.nottinghampost.com/",
    "tags": [
      "CW:Midlands",
      "Nottingham Post"
    ],
    "postcode": "NG7 4HF"
  },
  {
    "label": "Nuneaton News",
    "value": "coventrytelegraph",
    "printTitle": "Nuneaton News",
    "printTitleId": "62c3030cedcea442ed2e6a3c",
    "url": "https://www.coventrytelegraph.net/",
    "tags": [
      "CW:Midlands",
      "Nuneaton News"
    ],
    "postcode": "CV10 7EZ"
  },
  {
    "label": "Ormskirk Advertiser",
    "value": "liverpoolecho",
    "printTitle": "Ormskirk Advertiser",
    "printTitleId": "62c3041aedcea442ed2e6a9b",
    "url": "https://www.liverpoolecho.co.uk/",
    "tags": [
      "CW:North West",
      "Ormskirk Advertiser"
    ],
    "postcode": "L39 2YN"
  },
  {
    "label": "Paisley Daily Express",
    "value": "dailyrecord",
    "printTitle": "Paisley Daily Express",
    "printTitleId": "62c3034eedcea442ed2e6a55",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:PDE",
      "Paisley Daily Express"
    ],
    "postcode": "PA1 1GZ"
  },
  {
    "label": "Perthshire Advertiser",
    "value": "dailyrecord",
    "printTitle": "Perthshire Advertiser",
    "printTitleId": "62c3037cedcea442ed2e6a6d",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:PTA",
      "Perthshire Advertiser"
    ],
    "postcode": "PH1 5TF"
  },
  {
    "label": "Pontypridd Observer",
    "value": "walesonline",
    "printTitle": "Pontypridd Observer",
    "printTitleId": "62c304b374a4f4a20f2a0680",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Pontypridd Observer"
    ],
    "postcode": "CF37 1AA"
  },
  {
    "label": "Redditch Advertiser",
    "value": "birminghammail",
    "printTitle": "Redditch Advertiser",
    "printTitleId": "62c303b2d9c111288c0d0089",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands"
    ],
    "postcode": "DY1 4SQ"
  },
  {
    "label": "Retford Times",
    "value": "lincolnshirelive",
    "printTitle": "Retford Times",
    "printTitleId": "62c40d5574a4f4a20f2a08c1",
    "url": "https://www.lincolnshirelive.co.uk/",
    "tags": [
      "CW:North East",
      "Retford Times"
    ],
    "postcode": "DN22 6RJ"
  },
  {
    "label": "Rochdale Observer",
    "value": "men",
    "printTitle": "Rochdale Observer",
    "printTitleId": "62c303eed9c111288c0d00b0",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West",
      "Rochdale Observer"
    ],
    "postcode": "OL16 1AQ"
  },
  {
    "label": "Rossendale Free Press",
    "value": "accrington",
    "printTitle": "Rossendale Free Press",
    "printTitleId": "62c410eae8e485262f3237f1",
    "url": "https://www.lancs.live/",
    "tags": [
      "CW:North West",
      "Rossendale Free Press"
    ],
    "postcode": "BB4 7PL"
  },
  {
    "label": "Runcorn & Widnes Weekly News",
    "value": "liverpoolecho",
    "printTitle": "Runcorn & Widnes Weekly News",
    "printTitleId": "62c3048ce8e485262f321ed5",
    "url": "https://www.liverpoolecho.co.uk/",
    "tags": [
      "CW:North West",
      "Runcorn Weekly News"
    ],
    "postcode": "WA7 2PY"
  },
  {
    "label": "Rutherglen Reformer",
    "value": "dailyrecord",
    "printTitle": "Rutherglen Reformer",
    "printTitleId": "62bc6d19d849abd9dc0c4d88",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:RGR",
      "Rutherglen Reformer"
    ],
    "postcode": "G73 3RJ"
  },
  {
    "label": "Sandbach Chronicle",
    "value": "chesterchronicle",
    "printTitle": "Sandbach Chronicle",
    "printTitleId": "62c3052ad9c111288c0d011d",
    "url": "https://www.cheshire-live.co.uk/",
    "tags": [
      "CW:North West"
    ],
    "postcode": "CH1 2DY"
  },
  {
    "label": "Scunthorpe Telegraph",
    "value": "grimsbytelegraph",
    "printTitle": "Scunthorpe Telegraph",
    "printTitleId": "62bc6cf2d9c111288c0ccd15",
    "url": "https://www.grimsbytelegraph.co.uk/",
    "tags": [
      "CW:North East",
      "Scunthorpe Telegraph"
    ],
    "postcode": "DN15 6PY"
  },
  {
    "label": "Sevenoaks Chronicle",
    "value": "kentlive",
    "printTitle": "Sevenoaks Chronicle",
    "printTitleId": "62bc6cc0d849abd9dc0c4d47",
    "url": "https://www.kentlive.news/",
    "tags": [
      "CW:TMS",
      "Sevenoaks Chronicle"
    ],
    "postcode": "TN13 1DP"
  },
  {
    "label": "Sleaford Target",
    "value": "lincolnshirelive",
    "printTitle": "Sleaford Target",
    "printTitleId": "62c30552e8e485262f321f09",
    "url": "https://www.lincolnshirelive.co.uk/",
    "tags": [
      "CW:North East"
    ],
    "postcode": "DN22 6RJ"
  },
  {
    "label": "Somerset Standard & Guardian",
    "value": "somersetlive",
    "printTitle": "Somerset Standard & Guardian",
    "printTitleId": "62c4128a74a4f4a20f2a0ae4",
    "url": "https://www.somersetlive.co.uk/",
    "tags": [
      "CW:South West",
      "Somerset Guardian"
    ],
    "postcode": "BA1 2DA"
  },
  {
    "label": "South Lincolnshire Target Series",
    "value": "lincolnshirelive",
    "printTitle": "South Lincolnshire Target Series",
    "printTitleId": "62c2fe79016a277c265cec86",
    "url": "https://www.lincolnshirelive.co.uk/news/",
    "tags": [
      "CW:North East",
      "Lincolnshire Echo"
    ],
    "postcode": "LN11 9PY"
  },
  {
    "label": "South Wales Echo",
    "value": "walesonline",
    "printTitle": "South Wales Echo",
    "printTitleId": "62bc6cc0d849abd9dc0c4d47",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "South Wales Echo"
    ],
    "postcode": "TN13 1DP"
  },
  {
    "label": "South Wales Evening Post",
    "value": "walesonline",
    "printTitle": "South Wales Evening Post",
    "printTitleId": "62c3057cedcea442ed2e6b16",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "South Wales Evening Post"
    ],
    "postcode": "CF63 4HB"
  },
  {
    "label": "Southport Visiter",
    "value": "liverpoolecho",
    "printTitle": "Southport Visiter",
    "printTitleId": "62bc2e54d849abd9dc0c4c20",
    "url": "https://www.liverpoolecho.co.uk/",
    "tags": [
      "CW:North West",
      "Southport Visiter"
    ],
    "postcode": "PR9 0TY"
  },
  {
    "label": "Staffordshire Newsletter",
    "value": "stokesentinel",
    "printTitle": "Staffordshire Newsletter",
    "printTitleId": "62c305a5d9c111288c0d0143",
    "url": "https://www.stokesentinel.co.uk/",
    "tags": [
      "CW:Midlands",
      "Staffordshire Newsletter"
    ],
    "postcode": "ST16 2QZ"
  },
  {
    "label": "Staines Informer",
    "value": "getsurrey",
    "printTitle": "Staines Informer",
    "printTitleId": "62c305d8e8e485262f321f21",
    "url": "https://www.getsurrey.co.uk/",
    "tags": [
      "CW:TMS"
    ],
    "postcode": "RH4 1AT"
  },
  {
    "label": "Stirling Observer",
    "value": "dailyrecord",
    "printTitle": "Stirling Observer",
    "printTitleId": "62c30600e8e485262f321f39",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:SLO",
      "Stirling Observer"
    ],
    "postcode": "FK8 1NP"
  },
  {
    "label": "Stockport Express",
    "value": "men",
    "printTitle": "Stockport Express",
    "printTitleId": "62c3062be8e485262f321f4e",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West",
      "Stockport Express"
    ],
    "postcode": "SK1 2BT"
  },
  {
    "label": "Stoke Sentinel",
    "value": "stokesentinel",
    "printTitle": "Stoke Sentinel",
    "printTitleId": "62b5e6f4dac12f02111fa971",
    "url": "https://www.stokesentinel.co.uk/",
    "tags": [
      "CW:Midlands",
      "Stoke Sentinel"
    ],
    "postcode": "ST4 1AW"
  },
  {
    "label": "Stratford-upon-Avon Herald",
    "value": "birminghammail",
    "printTitle": "Stratford-upon-Avon Herald",
    "printTitleId": "62c3065d74a4f4a20f2a0865",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands"
    ],
    "postcode": "DY1 4SQ"
  },
  {
    "label": "Strathearn Herald",
    "value": "dailyrecord",
    "printTitle": "Strathearn Herald",
    "printTitleId": "62b5e6c974a4f4a20f29cf21",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:SSH",
      "Strathearn Herald"
    ],
    "postcode": "PH3 1HE"
  },
  {
    "label": "Sunday Mail",
    "value": "dailyrecord",
    "printTitle": "Sunday Mail",
    "printTitleId": "62b5e69dd849abd9dc0c4a5c",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "Sunday Mail"
    ],
    "postcode": "G3 8DA"
  },
  {
    "label": "Sunday Mercury",
    "value": "birminghammail",
    "printTitle": "Sunday Mercury",
    "printTitleId": "62b5e64774a4f4a20f29ceb4",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands",
      "Sunday Mercury"
    ],
    "postcode": "B49 5ED"
  },
  {
    "label": "Sunday Sun",
    "value": "nechronicle",
    "printTitle": "Sunday Sun",
    "printTitleId": "62c40d31d9c111288c0d02c1",
    "url": "https://www.chroniclelive.co.uk/",
    "tags": [
      "CW:North East",
      "Sunday Sun"
    ],
    "postcode": "NE1 7JB"
  },
  {
    "label": "Surrey Advertiser",
    "value": "getsurrey",
    "printTitle": "Surrey Advertiser",
    "printTitleId": "62c3068d74a4f4a20f2a088d",
    "url": "https://www.getsurrey.co.uk/",
    "tags": [
      "CW:TMS",
      "Surrey Advertiser"
    ],
    "postcode": "GU1 1QA"
  },
  {
    "label": "Surrey Mirror",
    "value": "getsurrey",
    "printTitle": "Surrey Mirror",
    "printTitleId": "62c4130c016a277c265cf122",
    "url": "https://www.getsurrey.co.uk/",
    "tags": [
      "CW:TMS",
      "Surrey Mirror"
    ],
    "postcode": "GU1 1QA"
  },
  {
    "label": "Tamworth Herald",
    "value": "birminghammail",
    "printTitle": "Tamworth Herald",
    "printTitleId": "62c306b8d9c111288c0d0194",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands",
      "Tamworth Herald"
    ],
    "postcode": "B79 7ND"
  },
  {
    "label": "The Chronicle",
    "value": "nechronicle",
    "printTitle": "The Chronicle",
    "printTitleId": "62b5e5bb016a277c265cb4f3",
    "url": "https://www.chroniclelive.co.uk/",
    "tags": [
      "CW:North East",
      "Newcastle Chronicle"
    ],
    "postcode": "NE1 1RQ"
  },
  {
    "label": "The Cornishman",
    "value": "cornwalllive",
    "printTitle": "The Cornishman",
    "printTitleId": "62b5e58ce8e485262f31fba2",
    "url": "https://www.cornwalllive.com/",
    "tags": [
      "CW:South West",
      "The Cornishman"
    ],
    "postcode": "TR8 5RB"
  },
  {
    "label": "The Gazette",
    "value": "gazettelive",
    "printTitle": "The Gazette",
    "printTitleId": "62b5dcaf016a277c265cb4bf",
    "url": "https://www.gazettelive.co.uk/",
    "tags": [
      "CW:North East",
      "Teesside Gazette"
    ],
    "postcode": "NE1 1RQ"
  },
  {
    "label": "The Herald",
    "value": "plymouthherald",
    "printTitle": "The Herald",
    "printTitleId": "62b5dc6174a4f4a20f29cdec",
    "url": "https://www.plymouthherald.co.uk/",
    "tags": [
      "CW:South West",
      "Plymouth Herald"
    ],
    "postcode": "PL3 4HQ"
  },
  {
    "label": "The Journal",
    "value": "nechronicle",
    "printTitle": "The Journal",
    "printTitleId": "62c40d0974a4f4a20f2a07f4",
    "url": "https://www.chroniclelive.co.uk/",
    "tags": [
      "CW:North East",
      "Newcastle Journal"
    ],
    "postcode": "NE1 1RJ"
  },
  {
    "label": "Thurrock Gazette",
    "value": "essexlive",
    "printTitle": "Thurrock Gazette",
    "printTitleId": "62c306dedac12f02111fb3d3",
    "url": "https://www.essexlive.news/",
    "tags": [
      "CW:TMS"
    ],
    "postcode": "SS14 2NR"
  },
  {
    "label": "Torquay Herald Express",
    "value": "devonlive",
    "printTitle": "Torquay Herald Express",
    "printTitleId": "62c4135fe8e485262f32393a",
    "url": "https://www.devonlive.com/",
    "tags": [
      "CW:South West",
      "Torquay Herald Express"
    ],
    "postcode": "CF10 3RB"
  },
  {
    "label": "Uttoxeter Advertiser",
    "value": "derbytelegraph",
    "printTitle": "Uttoxeter Advertiser",
    "printTitleId": "62c30734edcea442ed2e6b50",
    "url": "https://www.derbytelegraph.co.uk/",
    "tags": [
      "CW:Midlands"
    ],
    "postcode": "DE1 2AW"
  },
  {
    "label": "Uxbridge Gazette",
    "value": "getwestlondon",
    "printTitle": "Uxbridge Gazette",
    "printTitleId": "62b5dc1ad849abd9dc0c49c0",
    "url": "https://www.mylondon.news/",
    "tags": [
      "CW:TMS",
      "Uxbridge Gazette (West London)"
    ],
    "postcode": "UB8 2DR"
  },
  {
    "label": "Wales on Sunday",
    "value": "walesonline",
    "printTitle": "Wales on Sunday",
    "printTitleId": "62c41c5374a4f4a20f2a0ae4",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Wales on Sunday"
    ],
    "postcode": "CF10 3RB"
  },
  {
    "label": "Walsall Advertiser",
    "value": "birminghammail",
    "printTitle": "Walsall Advertiser",
    "printTitleId": "62c3075edac12f02111fb3e8",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands"
    ],
    "postcode": "B5 4BU"
  },
  {
    "label": "Warwick Courier",
    "value": "coventrytelegraph",
    "printTitle": "Warwick Courier",
    "printTitleId": "62c307c174a4f4a20f2a09bb",
    "url": "https://www.coventrytelegraph.net/",
    "tags": [
      "CW:Midlands"
    ],
    "postcode": "CV10 7EZ"
  },
  {
    "label": "Wells Journal",
    "value": "somersetlive",
    "printTitle": "Wells Journal",
    "printTitleId": "62c307eb74a4f4a20f2a09d3",
    "url": "https://www.somersetlive.co.uk/",
    "tags": [
      "CW:South West"
    ],
    "postcode": "BA1 2DA"
  },
  {
    "label": "West Briton",
    "value": "cornwalllive",
    "printTitle": "West Briton",
    "printTitleId": "62c3082fd9c111288c0d0215",
    "url": "https://www.cornwalllive.com/",
    "tags": [
      "CW:South West",
      "West Briton"
    ],
    "postcode": "TR1 1QH"
  },
  {
    "label": "West Lothian Courier",
    "value": "dailyrecord",
    "printTitle": "West Lothian Courier",
    "printTitleId": "62c413cb016a277c265cf28d",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:WLC",
      "West Lothian Courier"
    ],
    "postcode": "EH48 1AX"
  },
  {
    "label": "Western Daily Press",
    "value": "bristolpost",
    "printTitle": "Western Daily Press",
    "printTitleId": "62c4135fe8e485262f32393a",
    "url": "https://www.bristolpost.co.uk/",
    "tags": [
      "CW:South West",
      "Western Daily Press"
    ],
    "postcode": "CF10 3RB"
  },
  {
    "label": "Western Gazette",
    "value": "somersetlive",
    "printTitle": "Western Gazette",
    "printTitleId": "62c3085adac12f02111fb44b",
    "url": "https://www.somersetlive.co.uk/",
    "tags": [
      "CW:South West",
      "Western Gazette"
    ],
    "postcode": "BA22 8RN"
  },
  {
    "label": "Western Mail",
    "value": "walesonline",
    "printTitle": "Western Mail",
    "printTitleId": "62c413c174a4f4a20f2a0ae4",
    "url": "https://www.walesonline.co.uk/",
    "tags": [
      "CW:Cardiff",
      "Western Mail"
    ],
    "postcode": "CF10 3RB"
  },
  {
    "label": "Western Morning News",
    "value": "devonlive",
    "printTitle": "Western Morning News",
    "printTitleId": "62c4135fe8e485262f32393a",
    "url": "https://www.devonlive.com/",
    "tags": [
      "CW:South West",
      "Western Morning News"
    ],
    "postcode": "CF10 3RB"
  },
  {
    "label": "Widnes & Runcorn Weekly News",
    "value": "liverpoolecho",
    "printTitle": "Widnes & Runcorn Weekly News",
    "printTitleId": "62c30893d9c111288c0d0237",
    "url": "https://www.liverpoolecho.co.uk/",
    "tags": [
      "CW:North West",
      "Runcorn Weekly News"
    ],
    "postcode": "L1 1EQ"
  },
  {
    "label": "Wigan Observer",
    "value": "men",
    "printTitle": "Wigan Observer",
    "printTitleId": "62c308b8e8e485262f3220a7",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West"
    ],
    "postcode": "SK1 2BT"
  },
  {
    "label": "Wilmslow Express",
    "value": "men",
    "printTitle": "Wilmslow Express",
    "printTitleId": "62c308e4edcea442ed2e6b98",
    "url": "https://www.manchestereveningnews.co.uk/",
    "tags": [
      "CW:North West"
    ],
    "postcode": "SK1 2BT"
  },
  {
    "label": "Wishaw Press",
    "value": "dailyrecord",
    "printTitle": "Wishaw Press",
    "printTitleId": "62c30907edcea442ed2e6bb0",
    "url": "https://www.dailyrecord.co.uk/",
    "tags": [
      "CW:CQ:WSP",
      "Wishaw Press"
    ],
    "postcode": "ML2 7NG"
  },
  {
    "label": "Woking Advertiser",
    "value": "getsurrey",
    "printTitle": "Woking Advertiser",
    "printTitleId": "62c30936e8e485262f3220bb",
    "url": "https://www.getsurrey.co.uk/",
    "tags": [
      "CW:TMS"
    ],
    "postcode": "GU1 1QA"
  },
  {
    "label": "Wolverhampton Chronicle",
    "value": "birminghammail",
    "printTitle": "Wolverhampton Chronicle",
    "printTitleId": "62c3095aedcea442ed2e6bd1",
    "url": "https://www.birminghammail.co.uk/",
    "tags": [
      "CW:Midlands"
    ],
    "postcode": "B5 4BU"
  },
  {
    "label": "Worksop Guardian",
    "value": "lincolnshirelive",
    "printTitle": "Worksop Guardian",
    "printTitleId": "62c309b4d9c111288c0d026b",
    "url": "https://www.lincolnshirelive.co.uk/",
    "tags": [
      "CW:North East"
    ],
    "postcode": "B5 4BU"
  }
]